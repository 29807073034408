.isDesktop .review-header {
    padding: 5vh 0 4vh 2vw;
    -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.isDesktop .review-header .offer-cont {
    margin: 0 3.2vw;
}

.isDesktop .review-header .offer-cont .go-btn {
    margin-top: 0;
}

.isDesktop .review-header .score-wrapper {
    margin-top: 1vh;
}

.isDesktop .review-header .score-cont .score {
    margin-top: 0;
}

.isMobile .review-header .second-row {
    height: 20vw;
}

.isMobile .review-header .second-row .score-wrapper {
    height: inherit;
}

.isMobile .review-header .img-cont {
    width: 100%;
}

.isMobile .review-header .img-cont img {
    position: relative;
    display: block;
    width: 22vw;
    padding: 2vh 0;
    padding-left: 3vw;
}

.isMobile .review-header .img-cont .sitename {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.isMobile .review-header .offer-cont {
    clear: both;
    float: right;
    height: inherit;
}

.isMobile .review-header .background {
    background: #F7F7F7;
    width: 100%;
    padding-left: 3vw;
    box-sizing: border-box;
}

.isMobile .review-header .offer {
    display: table;
}

.isMobile .review-header .offer .go-btn {
    float: right;
}

.isMobile .review-header .offer .text {
    max-width: 50vw;
    float: left
}

.isMobile .review-header .score-cont {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.isDesktop .fixed .review-header, .isMobile .fixed .review-header .second-row {
    position: fixed;
    top: 0;
    z-index: 2;
}