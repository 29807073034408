.countdown {
    width: 10rem;
    height: 10rem;
    line-height: 10rem;
    font-size: 10rem;
    overflow: hidden;
    background: #3D88F8;
}

.countdown .count {
    width: inherit;
    height: inherit;
    line-height: inherit;
    color: #fff;
    text-align: center;
    display: block;
    text-shadow: 10px -6px #000;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}