.score-cont .rating img {
    margin: 0 3px;
}

.score-cont .score {
    font-size: 2.5rem;
}

.score-cont .max-score {
    font-size: 2rem;
}

.isDesktop .score-cont .score {
    margin-top: 50px;
}

.isDesktop .score-cont .rating {
    margin-top: 10px
}

.isMobile .score-cont .rating img {
    margin: 0 1px;
    width:13px;
}

.isMobile .rating {
    margin: -5px auto 0;
}
.isMobile .score-cont .score {
    line-height:100%;
    font-size:1.9rem;
}
.isMobile .score-cont .max-score {
    font-size: 1.3rem;
}