.go-to-site {
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.go-to-site .content {
    padding: 7vh 8vw;
}

.isMobile .go-to-site .content {
    width: 70vw;
}

.go-to-site .content .close {
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 60px;
    font-weight: 300;
    padding: 0 2vw;
    line-height: 60px;
}