
#start-popup{
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0.5);
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
}

.pop-content{
	width: 600px;
	position: relative;
    max-width: 96%;
}

.close-pop:after{
	display: inline-block;
	content: "\00d7";
	position: absolute;
	top: 6px;
	right: 6px;
	color: #fff;
	font-size: 40px;
	cursor: pointer;
	line-height: 34px;
	width: 40px;
	height: 40px;
	text-align: center;
}
.pop-content img {
	width: 100%;
	box-shadow: 0 0 40px -10px #000;
}

.close-pop:hover:after{
	color: #ccc;
	transform: scale(1.2);
}