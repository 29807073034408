.bottomBnr {
    position: fixed;
    bottom: -4px;
    width: 100%;
    z-index: 9;
}

.bottomBnr img {
    width: 100%;
}

.bottomBnr a {
    margin: 0;
    cursor: pointer;
}

.minimizeBnr span{
	height: 30px;
	line-height: 25px;
	width: 30px;
	text-align: center;
	position: absolute;
	right: 15px;
	top: 5px;
	color: #fff;
	font-size: 50px;
	cursor: pointer;
}

.minimizeBnr:hover{
	color: #bfb8b8;
}

.minimized .minimizeBnr span{
	display: none;
}

.minimized .minimizeBnr:before{
	content: '+';
    height: 20px;
    line-height: 16px;
	width: 30px;
	text-align: center;
	position: absolute;
	right: 15px;
	top: 5px;
	color: #fff;
	font-size: 30px;
	cursor: pointer;

}

@media only screen and (max-width: 800px) {
    .minimizeBnr{
        display: none;
    }
}