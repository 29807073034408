body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #DCDCDC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app {
  min-height: 80vh;
  position: relative;
  padding-bottom: 20vh;
}

/*position*/

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.border-box {
  box-sizing: border-box;
}

/*display*/

.d-table {
  display: table;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none;
}

.d-none-important {
  display: none !important;
}

.center-h {
  margin-left: auto;
  margin-right: auto;
}

.center-v {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-u {
  text-decoration: underline;
}

.color-white {
  color: #fff;
}

.color-orange {
  color: #F8593D;
}

.color-light-blue {
  color: #3D88F8;
}

.color-grey {
  color: #747474;
}

.color-dark-grey {
  color: #464341;
}

.color-black {
  color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-semi-black {
  background-color: rgba(0, 0, 0, 0.5)
}

.bg-black {
  background-color: #000;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.h-100 {
  height: 100%;
}

.w-inherit {
  width: inherit;
}

.h-inherit {
  height: inherit;
}

.z-index-1 {
  z-index: 1
}

.F12 {
  font-size: 12px;
}

.F13 {
  font-size: 13px;
}

.F14 {
  font-size: 14px;
}

.F15 {
  font-size: 15px;
}

.F16 {
  font-size: 16px;
}

.F17 {
  font-size: 17px;
}

.F18 {
  font-size: 18px;
}

.F19 {
  font-size: 19px;
}

.F20 {
  font-size: 20px;
}

.F21 {
  font-size: 21px;
}

.F22 {
  font-size: 22px;
}

.F23 {
  font-size: 23px;
}

.F24 {
  font-size: 24px;
}

.F25 {
  font-size: 25px;
}

.F26 {
  font-size: 26px;
}

.F30 {
  font-size: 30px;
}

.F36 {
  font-size: 36px;
}

.F40 {
  font-size: 40px;
}

.line-height-100 {
  line-height: 100%;
}

.line-height-125 {
  line-height: 125%;
}

.line-height-150 {
  line-height: 150%;
}

.float-left {
  float: left
}

.float-right {
  float: right
}

.clear-both {
  clear: both
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 40px;
}

.margin-top-60 {
  margin-top: 60px;
}

.pointer {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.isMobile img {
  pointer-events: none;
}

.block-screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.font-weight-100 {
  font-weight: 100
}

.font-weight-200 {
  font-weight: 200
}

.font-weight-300 {
  font-weight: 300
}

.font-weight-400 {
  font-weight: 400
}

.font-weight-500 {
  font-weight: 500
}

.font-weight-600 {
  font-weight: 600
}

.font-weight-700 {
  font-weight: 700
}

.font-weight-800 {
  font-weight: 800
}

.font-weight-900 {
  font-weight: 900
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.desktop-content-width {
  width: 74vw
}

.mobile-content-width {
  width: 100vw
}

.isMobile section.page {
  width: 90vw;
  padding-bottom: 10vh;
}

.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}