footer {
    bottom: 0;
}

footer .footer-links{
    background-color: #252423;
    margin-bottom: 80px;
}

footer nav {
    line-height: 8.5vh;
    font-size: 1.5vmin
}

footer a {
    text-decoration: none;
    color: white;
    margin: 0 1vw;
}

footer a:hover {
    text-decoration: underline;
}

.isMobile footer a {
    font-size: 0.6rem;
}